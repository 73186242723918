<template>
  <div :class="['v-select', isError && 'v-select--error', multiple && 'v-select--multiple']">
    <vue-select
      ref="select"
      v-bind="$attrs"
      :multiple="multiple"
      :calculate-position="withPopper"
      :dropdown-should-open="dropdownShouldOpen"
      :placeholder="placeholder"
      v-on="inheritListeners"
    >
      <template #search="{ attributes, events }">
        <slot name="search" :attributes="attributes" :events="events" :reset="reset"></slot>
      </template>
      <template #list-header>
        <slot name="list-header"></slot>
      </template>
      <template #open-indicator>
        <slot name="open-indicator">
          <span class="v-select__open-indicator"></span>
        </slot>
      </template>
      <template #no-options="{ search, searching }">
        <span></span>
        <slot v-if="isError" name="error"></slot>
        <em v-if="loading">Загрузка данных...</em>
        <em v-else-if="searching">Ничего не найдено по запросу: {{ search }}</em>
        <em v-else>Ничего не найдено</em>
      </template>
      <template #option="option">
        <slot name="option" :option="option"></slot>
      </template>
      <template #selected-option="{ label }">
        <slot name="selected-option" :label="label"></slot>
      </template>
    </vue-select>
  </div>
</template>

<script>
import VueSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

export default {
  name: 'VSelect',
  components: { VueSelect },
  inheritAttrs: false,
  props: {
    placeholder: { type: String, default: '' },
    // Props for vue-select see here - https://vue-select.org/api/props.html
    isError: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    dropdownShouldOpen: {
      type: Function,
      default({ noDrop, open, mutableLoading }) {
        return noDrop ? false : open && !mutableLoading
      }
    }
  },
  computed: {
    inheritListeners() {
      const { blur, ...restListeners } = this.$listeners
      // map native event to vue-select event
      return blur ? { ...restListeners, 'search:blur': blur } : restListeners
    }
  },
  methods: {
    reset() {
      this.$refs.select.search = ''
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            }
          },
          {
            name: 'setBodyScrollLockIgnore',
            enabled: true,
            phase: 'write',
            fn() {
              const { dropdownMenu } = component.$refs
              if (dropdownMenu) {
                dropdownMenu.setAttribute('body-scroll-lock-ignore', true)
              }
            }
          }
        ]
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    }
  }
}
</script>
