var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['v-select', _vm.isError && 'v-select--error', _vm.multiple && 'v-select--multiple']},[_c('vue-select',_vm._g(_vm._b({ref:"select",attrs:{"multiple":_vm.multiple,"calculate-position":_vm.withPopper,"dropdown-should-open":_vm.dropdownShouldOpen,"placeholder":_vm.placeholder},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_vm._t("search",null,{"attributes":attributes,"events":events,"reset":_vm.reset})]}},{key:"list-header",fn:function(){return [_vm._t("list-header")]},proxy:true},{key:"open-indicator",fn:function(){return [_vm._t("open-indicator",[_c('span',{staticClass:"v-select__open-indicator"})])]},proxy:true},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [_c('span'),(_vm.isError)?_vm._t("error"):_vm._e(),(_vm.loading)?_c('em',[_vm._v("Загрузка данных...")]):(searching)?_c('em',[_vm._v("Ничего не найдено по запросу: "+_vm._s(search))]):_c('em',[_vm._v("Ничего не найдено")])]}},{key:"option",fn:function(option){return [_vm._t("option",null,{"option":option})]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._t("selected-option",null,{"label":label})]}}],null,true)},'vue-select',_vm.$attrs,false),_vm.inheritListeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }